<template>
  <section id="StrategicAdvisor" class="StrategicAdvisor" data-aos="fade-up">
    <div class="container-fluid text-center">
      <div class="py-5 mt-5 container-wetforest">
        <div class="row m-0">
          <div class="d-block d-lg-none mb-4 ">
            <h2 >
              {{ data.blackTitle }}
            </h2>
            <h2  style="color: #14993c">{{ data.greenTitle }} </h2>
            <p
              id="strategicSubtituloMobile"
              class="body1 "
            >
              {{ data.description}}
            </p>
          </div>
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_detectar_desktop.webp"
                alt="Imagen detectar (mobile)"
                loading="lazy"
              />
              <div
                class="
                  texto-sobre-img-valores
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <h4 id="strategicDetectarH6a" class="card-title">{{ data.cards.first.title }}</h4>
              </div>
              <div
                class="
                  over-texto-sobre-img
                  d-flex
                  align-items-center
                  justify-content-center
                "
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <h4
                    id="strategicDetectarH6b"
                    class="card-title text-white text-center"
                  >
                    {{ data.cards.first.title }}
                  </h4>
                  <p id="strategicDetectarP" class="texto text-white">
                    {{ data.cards.first.description }}
                  </p>
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_ideas_desktop.webp"
                alt="Imagen transformar ideas (mobile)"
                loading="lazy"
              />
              <div
                class="
                  texto-sobre-img-valores
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <h4 id="strategicTransformarH6a" class="card-title"> {{ data.cards.second.title }}</h4>
              </div>
              <div
                class="
                  over-texto-sobre-img
                  d-flex
                  align-items-center
                  justify-content-center
                "
                style="background-color: #40b9e6"
              >
                <div class="text-center">
                  <h4
                    id="strategicTransformarH6b"
                    class="card-title text-white text-center"
                  > {{ data.cards.second.title }} </h4>
                  <p id="strategicTransformarP" class="texto text-white">{{ data.cards.second.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_brechas_desktop.webp"
                alt="Imagen acortar brechas (mobile)"
                loading="lazy"
              />
              <div
                class="
                  texto-sobre-img-valores
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <h4 id="strategicAcortarH6a" class="card-title">{{ data.cards.third.title }}</h4>
              </div>
              <div
                class="
                  over-texto-sobre-img
                  d-flex
                  align-items-center
                  justify-content-center
                "
                style="background-color: #edd621"
              >
                <div class="text-center">
                  <h4
                    id="strategicAcortarH6b"
                    class="card-title text-dark text-center"
                  >{{ data.cards.third.title }}</h4>
                  <p id="strategicAcortarP" class="texto text-dark">{{ data.cards.third.description }}</p>
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_fortalecer_desktop.webp"
                alt="Imagen crear y fortalecer (mobile)"
                loading="lazy"
              />
              <div
                class="
                  texto-sobre-img-valores
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <h4 id="strategicCrearH6a" class="card-title">{{ data.cards.fourth.title }}</h4>
              </div>
              <div
                class="
                  over-texto-sobre-img
                  d-flex
                  align-items-center
                  justify-content-center
                "
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <h4 id="strategicCrearH6b" class="card-title text-white text-center"> {{ data.cards.fourth.title }}</h4>
                  <p id="strategicCrearP" class="texto text-white">{{ data.cards.fourth.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-none d-lg-block mb-4 text-start">
              <h2 class="fw-bold"  style="color: #14993c">
                {{ data.blackTitle }}
              </h2>
              <h2 class="fw-bold">{{ data.greenTitle }} </h2>
              <p
                id="strategicSubtitulo"
                class="body1"
              >
                {{ data.description }}
              </p>
            </div>
            <div class="row m-0">
              <div class="col-md-6 pr-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_definir_desktop.webp"
                    alt="Imagen definir (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="
                      texto-sobre-img-valores
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <h4 id="strategicDefinirH6a" class="card-title">{{ data.cards.fifth.title }}</h4>
                  </div>
                  <div
                    class="
                      over-texto-sobre-img
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    style="background-color: #edd621"
                  >
                    <div class="text-center">
                      <h4
                        id="strategicDefinirH6b"
                        class="card-title text-dark text-center"
                      >{{ data.cards.fifth.title }}</h4>
                      <p id="strategicDefinirP" class="texto text-dark">{{ data.cards.fifth.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_comprometer_desktop.webp"
                    alt="Imagen comprometer (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="
                      texto-sobre-img-valores
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <h4 id="strategicComprometerH6a" class="card-title">{{ data.cards.sixth.title }}</h4>
                  </div>
                  <div
                    class="
                      over-texto-sobre-img
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    style="background-color: #40b9e6"
                  >
                    <div class="text-center">
                      <h4
                        id="strategicComprometerH6b"
                        class="card-title text-white text-center"
                      >{{ data.cards.sixth.title }}</h4>
                      <p
                        id="strategicComprometerP"
                        class="texto text-white"
                      >{{ data.cards.sixth.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

// import {ConsultaSeccionStrategicAdvisor} from "@/helpers/contenido.js";
import store from '../../store';
export default {
  name: "StrategicAdvisor",
  data() {
    return {
      data: {
        greenTitle: 'Strategic Advisors',
        blackTitle: 'lideras los programas',
        description: 'A través de un acompañamiento estrecho y comprometido para:',
        cards: {
          first: {
            title: 'Detectar',
            description: 'En forma rápida desafíos y oportunidades de mejora.'
          },
          second: {
            title: 'Transformar Ideas',
            description: 'En acciones y/o proyectos concretos.'
          },
          third: {
            title: 'Acortar Brechas',
            description: 'Entre situaciones actuales e ideales, con métricas de resultados, sincronizadas con el alineamiento estratégico de la compañía.'
          },
          fourth: {
            title: 'Crear y Fortalecer',
            description: 'Procesos de mejora continua de la compañia, estructurado para su crecimineto y escalamiento global.'
          },
          fifth: {
            title: 'Definir',
            description: 'Roles y funciones claras.'
          },
          sixth: {
            title: 'Comprometer',
            description: 'Los programas con la ejecución, completando la organización, conscientes de las dificultades de compatibilizar el día a día con acciones de mayor alcance.'
          }
        },
      }

      // titulo_negro: '',
      // titulo_verde: '',
      // description: '',
      // detectar_card: '',
      // ideas_card: '',
      // acortar_card: '',
      // crear_card: '',
      // definir_card: '',
      // comprometer_card: ''
    };
  },
  methods: {

    
    fetchData() {

       if (store.state.sections.strategicAdvisors) {
      this.data = store.state.sections.strategicAdvisors.components;}
    }

  },
  watch: {
    '$store.state.sections': function () {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData()
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

.texto {
  font-size: 1rem !important;
}

.StrategicAdvisor {
  position: relative;
  margin-top: 5%;

  p {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

.sub-titulo {
  color: #414141;
  text-align: left;
}

.container-over {
  position: relative;
  overflow: hidden;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}


.img-sobre {
  border-radius: 18px;
}

.texto-sobre-img-valores {
  position: absolute;
  top: 0;
  left: 0px;
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: center; */
  z-index: 2;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.over-texto-sobre-img {
  position: absolute;
  top: 0;
  left: 0px;
  border: none;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
  color: #FFF;
  padding: 1rem;
  border-radius: 18px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.over-texto-sobre-img:hover {
  opacity: 1;
}


@media (max-width: 982px) {
  .img-sobre {
    height: 250px;
    object-fit: cover;
  }

  /*.titulo-negro {*/
  /*  font-size: 2rem;*/
  /*}*/

  /*.titulo-verde {*/
  /*  font-size: 1.5rem;*/
  /*}*/
}

</style>
